import config from "config";
import { authHeader, handleResponse, logout } from "../_helpers";

// --------------- MOVED TO APIHelper.js --------------
//function logout() {
// remove user from local storage to log user out
//     localStorage.removeItem('user');
// }

// --------------- MOVED TO APIHelper.js --------------
// function handleResponse(response) {debugger;
//     return response.text().then(text => {
//         const data = text && JSON.parse(text);
//         if (!response.ok) {
//             if (response.status === 401) {
//                 // auto logout if 401 response returned from api
//                 logout();
//                 location.reload(true);
//             }

//             const error = (data && data.message) || response.statusText;
//             return Promise.reject(error);
//         }

//         return data;
//     });
// }

function login(email, password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email, password })
  };

  return fetch(`${config.apiUrl}/user/login`, requestOptions)
    .then(handleResponse)
    .then(user => {
      // login successful if there's a jwt token in the response
      if (user.token) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem("user", JSON.stringify(user));
        const rememberMeCheckbox = document.getElementById("checkbox-1");
        if (rememberMeCheckbox) {
          if (rememberMeCheckbox.checked) {
            const now = new Date();
            const formattedDate = now.toDateString() + " " + now.toLocaleTimeString();
            const rememberMe = JSON.stringify({ email, createdOn: formattedDate });
            localStorage.setItem("rememberMe", rememberMe);
          }
        }
      }

      return user;
    });
}

function sendMail(email) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email })
  };

  return fetch(`${config.apiUrl}/user/forgotpassword`, requestOptions).then(
    handleResponse
  );
}

function getSubscriberUsers() {
  const requestOptions = {
    method: "POST",
    headers: {...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({})
  };

  return fetch(`${config.apiUrl}/user/list/subscriber`, requestOptions).then(
    handleResponse
  );
}

function commitSubscriberUser(user) {
  const requestOptions = {
    method: "POST",
    headers: {...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(user)
  };

  return fetch(`${config.apiUrl}/user/commit`, requestOptions).then(
    handleResponse
  );
}

function getRoles() {
  const requestOptions = {
    method: "POST",
    headers: {...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({})
  };

  return fetch(`${config.apiUrl}/user/roles`, requestOptions).then(
    handleResponse
  );
}

function getTimezones() {
  const requestOptions = {
    method: "POST",
    headers: {...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({})
  };

  return fetch(`${config.apiUrl}/user/timezones`, requestOptions).then(
    handleResponse
  );
}

function resetPassword(key) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" }
  };

  return fetch(
    `${config.apiUrl}/user/resetpassword/${key}`,
    requestOptions
  ).then(handleResponse);
}

function submitPassword(forgotPasswordValidationKey, password) {
  const requestOptions = {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ forgotPasswordValidationKey, password })
  };

  return fetch(`${config.apiUrl}/user/reset`, requestOptions).then(
    handleResponse
  );
}

function twoFaRequest() {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" }
  };

  return fetch(`${config.apiUrl}/user/twofa`, requestOptions).then(
    handleResponse
  );
}

function twoFaSubmit(code) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" }
  };

  return fetch(`${config.apiUrl}/user/twofasubmit/${code}`, requestOptions)
    .then(handleResponse)
    .then(user => {
      // login successful if there's a jwt token in the response
      if (user.token && user.twoAuthToken) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem("user", JSON.stringify(user));
      }

      return user;
    });
}

function register(user) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(user)
  };

  return fetch(`${config.apiUrl}/user/register`, requestOptions).then(
    handleResponse
  );
}

function getById(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(`${config.apiUrl}/user/${id}`, requestOptions).then(
    handleResponse
  );
}

function update(user) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(user)
  };

  return fetch(`${config.apiUrl}/user/${user.id}`, requestOptions).then(
    handleResponse
  );
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader()
  };

  return fetch(`${config.apiUrl}/user/${id}`, requestOptions).then(
    handleResponse
  );
}

export const userService = {
  login,
  sendMail,
  resetPassword,
  submitPassword,
  twoFaRequest,
  twoFaSubmit,
  logout,
  register,
  getById,
  update,
  getRoles,
  getTimezones,
  getSubscriberUsers,
  commitSubscriberUser,
  delete: _delete
};
