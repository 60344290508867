<template>
    <div>
        <!--Add new Modal-->
        <b-modal id="modalAdd" title-class="modalTitle" title="Add New User" @hidden="flags = ''" centered hide-footer no-fade :visible="flags === 'addUser'">
            <div class="card" id="reportCard">
                <div class="card-body">
                    <form class="edits" ref="addModal">
                        <div class="form-block">
                            <label for="firstNameUserAdd" class="d-block" >First Name:</label>
                            <input id="firstNameUserAdd" class="form-control" type="text" required v-model="tempUser.firstName">
                        </div>
                        <div class="form-block">
                            <label for="lastNameUserAdd" class="d-block" >Last Name:</label>
                            <input id="lastNameUserAdd" class="form-control" type="text" required v-model="tempUser.lastName">
                        </div>
                        <div class="form-block">
                            <label for="emailUserAdd" class="d-block" >Email:</label>
                            <input id="emailUserAdd" class="form-control" type="email" required v-model="tempUser.email">
                        </div>
                        <div class="form-block">
                            <label for="emailNotificationUserAdd" class="d-block" >Email Notifications:</label>
                            <div class="OptionSwitch">
                                <div>
                                    <input type="checkbox" id="emailNotificationUserAdd" data-switch="success" v-model="tempUser.emailNotifications"/>
                                    <label for="emailNotificationUserAdd" data-on-label="Yes" data-off-label="No" class="mb-0 d-block"></label>
                                </div>
                            </div>
                        </div>
                        <div class="form-block">
                            <label for="roleUserAdd" class="d-block" >Role:</label>
                            <select id="roleUserAdd" class="form-control" required v-model="tempUser.roleID">
                                <template v-for="role in management.roles"><option v-if="role.name !== 'Owner'" :key="role.id" :value="role.id">{{ role.name }}</option></template>
                            </select>
                        </div>
                        <div class="form-block">
                            <label for="timezonesUserAdd" class="d-block" >TImezone:</label>
                            <select id="timezonesUserAdd" class="form-control" required v-model="tempUser.timeZoneID">
                                <option v-for="zone in management.timezones" :key="zone.id" :value="zone.id">{{ zone.timezone }}: GMT{{zone.gmtOffset > 0 ? "+" : ""}}{{ zone.gmtOffset }}</option>
                            </select>
                        </div>
                        <div class="form-block">
                            <label for="passwordUserAdd" class="d-block" style="margin-bottom: 0;" >Password:</label>
                            <p style="margin-bottom: .5em; font-size: x-small">Requires: 8+ characters, a number, an uppercase letter, and a special character.</p>
                            <input id="passwordUserAdd" class="form-control" type="text" required v-model="tempPassword">
                        </div>
                        <div class="form-block">
                            <label for="activeUserAdd" class="d-block" >Active:</label>
                            <div class="OptionSwitch">
                                <div>
                                    <input type="checkbox" id="activeUserAdd" data-switch="success" v-model="tempUser.active"/>
                                    <label for="activeUserAdd" data-on-label="Yes" data-off-label="No" class="mb-0 d-block"></label>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <!-- Emulate built in modal footer ok and cancel button actions -->
            <div class="row mb-2 float-right">
                <div class="col pr-0">
                    <b-button class="float-right echeckpointBlue modalBtn" size="sm" @click="flags=''">Cancel</b-button>
                </div>
                <div class="col-auto">
                    <b-button type="button" class="float-right userButton modalBtn" size="sm" @click="Commit('adduser')">Submit</b-button>
                </div>
            </div>
        </b-modal>

        <!--edit modal-->
        <b-modal id="modalEdit" title-class="modalTitle" title="Edit User" @hidden="resetModals();flags = ''" centered hide-footer no-fade :visible="flags === 'editUser'">
            <div class="card" id="reportCard">
                <div class="card-body">
                    <form class="edits" ref="editModal">
                        <div class="form-block">
                            <label for="firstNameUserEdit" class="d-block" >First Name:</label>
                            <input id="firstNameUserEdit" class="form-control" type="text" required v-model="tempUser.firstName">
                        </div>
                        <div class="form-block">
                            <label for="lastNameUserEdit" class="d-block" >Last Name:</label>
                            <input id="lastNameUserEdit" class="form-control" type="text" required v-model="tempUser.lastName">
                        </div>
                        <div class="form-block">
                            <label for="emailUserEdit" class="d-block" >Email:</label>
                            <input id="emailUserEdit" class="form-control" type="email" required v-model="tempUser.email">
                        </div>
                        <div class="form-block">
                            <label for="emailNotificationUserEdit" class="d-block" >Email Notifications:</label>
                            <div class="OptionSwitch">
                                <div>
                                    <input type="checkbox" id="emailNotificationUserEdit" data-switch="success" v-model="tempUser.emailNotifications"/>
                                    <label for="emailNotificationUserEdit" data-on-label="Yes" data-off-label="No" class="mb-0 d-block"></label>
                                </div>
                            </div>
                        </div>
                        <div class="form-block">
                            <label for="roleUserEdit" class="d-block" >Role:</label>
                            <select id="roleUserEdit" class="form-control" required v-model="tempUser.roleID">
                                <template v-for="role in management.roles"><option v-if="role.name !== 'Owner'" :key="role.id" :value="role.id">{{ role.name }}</option></template>
                            </select>
                        </div>
                        <div class="form-block">
                            <label for="timezonesUserEdit" class="d-block" >TImezone:</label>
                            <select id="timezonesUserEdit" class="form-control" required v-model="tempUser.timeZoneID">
                                <option v-for="zone in management.timezones" :key="zone.id" :value="zone.id">{{ zone.timezone }}: GMT{{zone.gmtOffset > 0 ? "+" : ""}}{{ zone.gmtOffset }}</option>
                            </select>
                        </div>
                        <div class="form-block">
                            <label for="passwordUserEdit" class="d-block" style="margin-bottom: 0;" >Change Password:</label>
                            <p style="margin-bottom: .5em; font-size: x-small">Requires: 8+ characters, a number, an uppercase letter, and a special character.</p>
                            <input id="passwordUserEdit" class="form-control" type="text" required v-model="tempPassword">
                        </div>
                        <div class="form-block">
                            <label for="activeUserEdit" class="d-block" >Active:</label>
                            <div class="OptionSwitch">
                                <div>
                                    <input type="checkbox" id="activeUserEdit" data-switch="success" v-model="tempUser.active"/>
                                    <label for="activeUserEdit" data-on-label="Yes" data-off-label="No" class="mb-0 d-block"></label>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <!-- Emulate built in modal footer ok and cancel button actions -->
            <div class="row mb-2 float-right">
                <div class="col pr-0">
                    <b-button class="float-right echeckpointBlue modalBtn" size="sm" @click="flags=''">Cancel</b-button>
                </div>
                <div class="col-auto">
                    <b-button type="button" class="float-right userButton modalBtn" size="sm" @click="Commit('edituser')">Submit</b-button>
                </div>
            </div>
        </b-modal>

        <!--Add API Modal-->
        <b-modal id="modalAPIAdd" title-class="modalTitle" title="Add New API User" @hidden="flags = ''" centered hide-footer no-fade :visible="flags === 'addApi'">
            <div class="card" id="reportCard">
                <div class="card-body">
                    <form class="edits" ref="addAPIModal">
                        <div class="form-block">
                            <label for="firstNameAPIAdd" class="d-block" >Name</label>
                            <input id="firstNameAPIAdd" class="form-control" type="text" required v-model="tempUser.firstName">
                        </div>
                        <div class="form-block">
                            <label for="flowAPIAdd" class="d-block" >Checkpoint:</label>
                            <select id="flowAPIAdd" class="form-control" required v-model="tempUser.serviceFlowID">
                                <option v-for="flow in Flows" :key="flow.Id" :value="flow.Id">{{ flow.Name }}</option>
                            </select>
                        </div>
                        <div class="form-block">
                            <label for="activeAPIAdd" class="d-block" >Active:</label>
                            <div class="OptionSwitch">
                                <div>
                                    <input type="checkbox" id="activeAPIAdd" data-switch="success" v-model="tempUser.active"/>
                                    <label for="activeAPIAdd" data-on-label="Yes" data-off-label="No" class="mb-0 d-block"></label>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <!-- Emulate built in modal footer ok and cancel button actions -->
            <div class="row mb-2 float-right">
                <div class="col pr-0">
                    <b-button class="float-right echeckpointBlue modalBtn" size="sm" @click="flags=''">Cancel</b-button>
                </div>
                <div class="col-auto">
                    <b-button type="button" class="float-right userButton modalBtn" size="sm" @click="Commit('addapi')">Submit</b-button>
                </div>
            </div>
        </b-modal>

        <!--edit API modal-->
        <b-modal id="modalAPIEdit" title-class="modalTitle" :title="confirmflag ? 'Confirm Key Generation' : 'Edit API User'" @hidden="flags = ''" centered hide-footer no-fade :visible="flags === 'editApi'">
            <template v-if="!confirmflag">
                <div class="card" id="reportCard">
                    <div class="card-body">
                        <form class="edits" ref="editAPIModal">
                            <div class="form-block">
                                <label for="firstNameAPIEdit" class="d-block" >Name</label>
                                <input id="firstNameAPIEdit" class="form-control" type="text" required v-model="tempUser.firstName">
                            </div>
                            <div class="form-block">
                                <label for="flowAPIEdit" class="d-block" >Checkpoint:</label>
                                <select id="flowAPIEdit" class="form-control" required v-model="tempUser.serviceFlowID">
                                    <option v-for="flow in Flows" :key="flow.Id" :value="flow.Id">{{ flow.Name }}</option>
                                </select>
                            </div>
                            <div class="form-block">
                                <label for="newKeyAPIEdit" class="d-block" >Generate New API Key:</label>
                                <div class="OptionSwitch">
                                    <div>
                                        <input type="checkbox" id="newKeyAPIEdit" data-switch="success" v-model="newKey"/>
                                        <label for="newKeyAPIEdit" data-on-label="Yes" data-off-label="No" class="mb-0 d-block"></label>
                                    </div>
                                </div>
                            </div>
                            <div class="form-block">
                                <label for="activeAPIEdit" class="d-block" >Active:</label>
                                <div class="OptionSwitch">
                                    <div>
                                        <input type="checkbox" id="activeAPIEdit" data-switch="success" v-model="tempUser.active"/>
                                        <label for="activeAPIEdit" data-on-label="Yes" data-off-label="No" class="mb-0 d-block"></label>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <!-- Emulate built in modal footer ok and cancel button actions -->
                <div class="row mb-2 float-right">
                    <div class="col pr-0">
                        <b-button class="float-right echeckpointBlue modalBtn" size="sm" @click="flags=''">Cancel</b-button>
                    </div>
                    <div class="col-auto">
                        <b-button type="button" class="float-right userButton modalBtn" size="sm" @click="validateConfirm('editapi')">Submit</b-button>
                    </div>
                </div>
            </template>
        <!--Edit confirmation if generating new api key-->
            <template v-if="confirmflag">
                <div class="card" id="reportCard">
                <div class="card-body">
                    <span>By generating a new API key, you will no longer be able to use the old key. Are you sure you want to do this?</span>
                </div>
            </div>
            <!-- Emulate built in modal footer ok and cancel button actions -->
            <div class="row mb-2 float-right">
                <div class="col pr-0">
                    <b-button class="float-right echeckpointBlue modalBtn" size="sm" @click="confirmflag = false">Cancel</b-button>
                </div>
                <div class="col-auto">
                    <b-button type="button" class="float-right userButton modalBtn" size="sm" @click="confirmflag = false; Commit('editapi')">Confirm</b-button>
                </div>
            </div>
            </template>
        </b-modal>
        
        <h4 class="group-title">User Management</h4>
        <p class="description">Add new users, edit and delete old ones. Also manage API keys.</p>
        <div class="groupSet card">
            <h4 class="header-title">Users</h4>
            <p></p>
            <table class="table table-centered mb-0">
                <thead class="User-thead">
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Role</th>
                        <th>Created</th>
                        <th>Active</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="user in management.users" :key="user.id">
                        <td class="tableText">{{user.firstName}} {{ user.lastName }}</td>
                        <td class="tableText">{{user.email}}</td>
                        <td class="tableText">{{management.roles.find(x => x.id === user.roleID).name}}</td>
                        <td class="tableText">{{formatFriendlyDate(user.createdOn)}}</td>
                        <td>
                            <div class="OptionSwitch" :style="user.roleID === 3 || user.id === loggedInUser.id ? 'pointer-events: none;': ''">
                                <div>
                                    <input type="checkbox" :id="'ActiveU' + user.id" data-switch="success" :checked="user.active" @click="activationStatus(user)"/>
                                    <label :for="'ActiveU' + user.id" data-on-label="Yes" data-off-label="No" class="mb-0 d-block"></label>
                                </div>
                            </div>
                        </td>
                        <td>
                            <button class="btnContainer" @click="EditUser(user)" title="Edit" :disabled="(user.roleID === 3 && loggedInUser.id !== user.id) || flags !== ''"><i class="mdi mdi-pencil"></i></button>
                            <!--button class="btnContainer" @click="ConfirmDeleteDomain(domain)" title="Delete" ><i class="mdi mdi-delete"></i></button-->
                        </td>
                    </tr>
                    <tr>
                        <td colspan="5"></td>
                        <td><button class="btnContainer" @click="AddUser()" title="Add New" :disabled="flags !== ''"><i class="mdi mdi-plus-circle"></i></button></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="groupSet no-flex card">
            <h4 class="header-title">API Users</h4>
            <!--p>Manage API users.</p-->
            <table class="table table-centered mb-0" style="table-layout: fixed;">
                <thead class="API-thead">
                    <tr>
                        <th>Name</th>
                        <th>Key</th>
                        <th></th>
                        <!--th>Email</th-->
                        <th>Checkpoint</th>
                        <th>Created</th>
                        <th>Active</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="apiUser in management.APIUsers" :key="apiUser.id">
                        <td class="tableText">{{apiUser.firstName}}</td>
                        <td class="APIKey tableText obscured">{{apiUser.apiKey}}</td>
                        <td class="tableText">
                            <button class="btnContainer" @click="ToggleObscure($event)"  title="Show/Hide"><i class="mdi mdi-eye ignore-click"></i></button> 
                            <button class="btnContainer" @click="Copy($event)"  title="Copy to Clipboard"><i class="mdi mdi-content-copy ignore-click"></i></button> 
                        </td>
                        <td class="tableText">{{ Flows[apiUser.serviceFlowID].Name }}</td>
                        <td class="tableText">{{formatFriendlyDate(apiUser.createdOn)}}</td>
                        <td>
                            <div class="OptionSwitch" :style="apiUser.roleID === 3 || apiUser.id === loggedInUser.id ? 'pointer-events: none;': ''">
                                <div>
                                    <input type="checkbox" :id="'ActiveA' + apiUser.id" data-switch="success" :checked="apiUser.active" @click="activationStatus(apiUser)"/>
                                    <label :for="'ActiveA' + apiUser.id" data-on-label="Yes" data-off-label="No" class="mb-0 d-block"></label>
                                </div>
                            </div>
                        </td>
                        <td>
                            <button class="btnContainer" @click="EditAPIUser(apiUser)" title="Edit" :disabled="flags !== ''"><i class="mdi mdi-pencil"></i></button>
                            <!--button class="btnContainer" @click="ConfirmDeleteDomain(domain)" title="Delete" :disabled="flags !== ''"><i class="mdi mdi-delete"></i></button-->
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6"></td>
                        <td><button class="btnContainer" @click="AddAPIUser()" title="Add New" :disabled="flags !== ''"><i class="mdi mdi-plus-circle"></i></button></td>
                    </tr>
                </tbody>
            </table>
            <div class="row-flex">
                
            </div>
        </div>
    </div>
</template>

<script>
import appConfig from "@/app.config";
import { mapState, mapActions } from "vuex";
import { formatFriendlyDate } from "../../../_helpers";

export default {
    name: "UserManagementPage",
    page: {
        title: "User Management",
        meta: [{ name: "description", content: appConfig.description }]
    },
    async created() 
    {
        this.getSubFlows();
        await this.getRoles();
        await this.getSubscriberUsers();
        await this.getTimezones();
        this.$forceUpdate();
    },
    data() 
    {   
        return {
            flags: "",
            confirmflag: false,
            tempUser: {
                active: true,
                apiKey: null,
                createdOn: "1970-01-01",
                email: null,
                emailNotifications: true,
                firstName: null,
                lastName: null,
                id: 0,
                roleID: 0,
                serviceFlowID: null,
                timeZoneID: 0
            },
            defaultUser: {
                active: true,
                apiKey: null,
                createdOn: "1970-01-01",
                email: null,
                emailNotifications: true,
                firstName: null,
                lastName: null,
                id: 0,
                roleID: 1,
                serviceFlowID: null,
                timeZoneID: 13
            },
            tempPassword: null,
            newKey: false,
            needed: []
        }
    },
    computed:{
        ...mapState({settings: state => state.settings}),
        ...mapState("settings", ["Flows"]),
        ...mapState("users", ["management"]),
        ...mapState({loggedInUser: state => state.account.user})
    },
    methods:{
        ...mapActions("settings", ["getSubFlows"]),
        ...mapActions("users", ["getSubscriberUsers", "getRoles", "getTimezones", "commitSubscriberUser"]),
        formatFriendlyDate: formatFriendlyDate,
        /*CloseModal(modalName)
        {
            this.$bvModal.hide(modalName);
        },*/
        AddDomain()
        {   
            this.resetModals();
            this.flags = "add"
        },
        validateEmail(email) {
            const emailRegex =
                new RegExp(/^[A-Za-z0-9_!#$%&'*+/=?`{|}~^.-]+@[A-Za-z0-9.-]+\.[^0-9_!#$%&'*+/=?`{|}~^.-]{2}[A-Za-z0-9_!#$%&'*+/=?`{|}~^.-]*/, "gm");
            const isValidEmail = emailRegex.test(email);
            return isValidEmail;
        },
        Copy(e)
        {
            const string = e.target.parentElement.previousElementSibling.innerHTML;
            navigator.clipboard.writeText(string)
        },
        ToggleObscure(e)
        {
            e.target.parentElement.previousElementSibling.classList.toggle("obscured");
            e.target.firstChild.classList.toggle("mdi-eye");
            e.target.firstChild.classList.toggle("mdi-eye-off");
        },
        EditUser(user)
        {
            this.resetModals();
            this.tempUser = {...user}
            this.flags = "editUser"

        },
        EditAPIUser(user)
        {
            this.resetModals();
            this.tempUser = {...user}
            this.flags = "editApi"
        },
        AddUser()
        {
            this.resetModals();
            this.flags = "addUser";
        },
        AddAPIUser()
        {
            this.resetModals();
            this.flags = "addApi";
        },
        resetModals()
        {
            this.tempUser = {...this.defaultUser};
            this.tempPassword = null;
            this.newKey = false;
            this.unredden();
        },
        async Commit(type)
        {
            this.unredden();
            if(!this.validate(type))
                return;
            
            if(this.newKey)
                this.tempUser.apiKey = null;
            if(this.tempUser.id === this.loggedInUser.id)
                this.tempUser.active = true;
            
            await this.commitSubscriberUser({user: this.tempUser, Password: this.tempPassword})
            await this.getSubscriberUsers();
            this.$forceUpdate();
            this.flags = "";
        },
        async activationStatus(user)
        {
            if(user.roleID === 3)
                return;
            user.active = !user.active;
            await this.commitSubscriberUser({user: user, Password: null})
            await this.getSubscriberUsers();
            this.$forceUpdate();
        },
        validate(type)
        {
            let valid = true;
            if(this.tempPassword === "")
                this.tempPassword = null;

            switch(type)
            {
                case 'adduser':
                    if(this.isNullOrEmpty(this.tempUser.firstName))
                    {
                        valid = false;
                        this.redden("firstNameUserAdd");
                    }
                    if(this.isNullOrEmpty(this.tempUser.lastName))
                    {
                        valid = false;
                        this.redden("lastNameUserAdd");
                    }
                    if(!this.validateEmail(this.tempUser.email))
                    {
                        valid = false;
                        this.redden("emailUserAdd")
                    }
                    if(!(this.validatePassword(this.tempPassword)))
                    {
                        valid = false;
                        this.redden("passwordUserAdd");
                    }
                    return valid;
                case 'edituser':
                    if(this.isNullOrEmpty(this.tempUser.firstName))
                    {
                        valid = false;
                        this.redden("firstNameUserEdit");
                    }
                    if(this.isNullOrEmpty(this.tempUser.lastName))
                    {
                        valid = false;
                        this.redden("lastNameUserEdit");
                    }
                    if(!this.validateEmail(this.tempUser.email))
                    {
                        valid = false;
                        this.redden("emailUserEdit")
                    }
                    if(!(this.validatePassword(this.tempPassword) || this.tempPassword === null))
                    {
                        valid = false;
                        this.redden("passwordUserEdit");
                    }
                    return valid;
                case 'addapi':
                    if(this.isNullOrEmpty(this.tempUser.firstName))
                    {
                        valid = false;
                        this.redden("firstNameAPIAdd");
                    }
                    if(this.tempUser.serviceFlowID === null)
                    {
                        valid = false;
                        this.redden("flowAPIAdd")
                    }
                    return valid;
                case 'editapi':
                    if(this.isNullOrEmpty(this.tempUser.firstName))
                    {
                        valid = false;
                        this.redden("firstNameAPIEdit");
                    }
                    if(this.tempUser.serviceFlowID === null)
                    {
                        valid = false;
                        this.redden("flowAPIEdit")
                    }
                    return valid;
                default:
                    return false;
            }
        },
        async validateConfirm(type)
        {
            debugger;
            if(!this.validate(type))
                return;

            if(this.newKey)
                this.confirmflag = true;

            else
                this.Commit(type);
        },
        isNullOrEmpty(str)
        {
            return str === null || str === "";
        },
        redden(name)
        {
            document.getElementById(name).classList.add("needed");
            this.needed.push(name);
        },
        unredden()
        {
            for(const name of this.needed)
            {
                const elem = document.getElementById(name);
                if(!elem)
                    continue;
                elem.classList.remove("needed");
            }
            this.needed = [];
        },
        validatePassword(password)
        {
            // eslint-disable-next-line
            const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
            if(password === null)
                return null;
            if (password.length < 8)
                return false;
            if(!/\d/.test(password))
                return false
            if(!/[A-Z]/.test(password))
                return false;
            if(!format.test(password))
                return false;
            
            return true
        }
    }
}
</script>

<style lang="scss" scoped>
.edits
{
    button
    {
        margin: 0 4px 0 8px;
    }
}

.form-block
{
    margin-bottom: 1em;
}

.User-thead
{
    :first-child
    {
        width: 200px;
        border-top: none;
    }
    :nth-child(2)
    {
        width:max-content;
        border-top: none;
    }
    /*:nth-child(3)
    {
        width: 250px;
        padding-left: 15px;
        border-top: none;
    }*/
    :nth-child(3)
    {
        width: 200px;
        padding-left: 15px;
        border-top: none;
    }
    :nth-child(4)
    {
        width: 125px;
        padding-left: 15px;
        border-top: none;
    }
    :nth-child(5)
    {
        width: 70px;
        padding-left: 0;
        border-top: none;
    }
    :nth-child(6)
    {
        width: 75px;
        padding-left: .5em;
        border-top: none;
        text-align: center;
    }
}

.API-thead
{
    :first-child
    {
        width: 200px;
        border-top: none;
    }
    :nth-child(2)
    {
        min-width: 60px;
        border-top: none;
    }
    :nth-child(3)
    {
        width: 90px;
        padding-left: 15px;
        border-top: none;
    }
    :nth-child(4)
    {
        width: 200px;
        padding-left: 15px;
        border-top: none;
    }
    :nth-child(5)
    {
        width: 125px;
        padding-left: 15px;
        border-top: none;
    }
    :nth-child(6)
    {
        width: 75px;
        padding-left: 0;
        border-top: none;
    }
    :nth-child(7)
    {
        width: 75px;
        padding-left: .5em;
        border-top: none;
        text-align: center;
    }
}

td 
{
    padding: 0;
}

.ColorCell
{
    text-align: center;
}

tbody:last-child
{   
    tr
    {
        border-bottom:none;
    }
}

tbody
{
    :last-child .btnContainer
    {
        margin-left: 25% !important;
        margin-right: 25% !important;
    }
}

.tableText
{
    font-size: 12pt;
    padding-left: 15px;
}

.needed
{
    outline: 4px solid #FF6666;
}

.row-flex
{
    display: flex;
    flex-direction: row;
}

pre
{
    white-space: pre;
    margin-bottom: .25em;
    width: fit-content;
    display: flex;
}

.APIKey
{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: min-content;
}

.obscured
{
    -webkit-text-security: disc;
    -moz-text-security: disc;
}

.ignore-click
{
    pointer-events: none;
}

.card-body
{
    .form-block:last-of-type
    {
        margin-bottom: 0;
    }
}
</style>