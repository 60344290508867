<template>
  <div>
    <b-form @submit.prevent="twoFaSubmit(concatenateCode());">
      <b-form-group class="mb-3" label="Security Code" label-for="twoFactor">
        <input
          v-for="n in twoFaLength"
          class="twofa form-control noDisplay"
          :class="{ noMargin: n == 6 }"
          maxlength="1"
          @input="advance(n, $event)"
          @keyup.delete="back(n, $event)"
          @paste="twoFaPaste"
          v-model="boxValues[n]"
          :key="n"
          ref="twofa"
          type="number"
          id="twoFactor"
          :state="twofaState"
        />
        <b-form-invalid-feedback :state="twofaState"
          >{{ account.status.error }}
        </b-form-invalid-feedback>
      </b-form-group>
      <div class="buttonContainer">
        <b-button
          block
          ref="submit"
          class="userButton"
          type="submit"
          variant="primary"
          size="sm"
          >Verify
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import "../design/assets/scss/app.scss";
import "../design/assets/scss/icons.scss";
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      twoFaLength: 6,
      boxValues: {
        1: null,
        2: null,
        3: null,
        4: null,
        5: null,
        6: null
      }
    };
  },
  computed: {
    ...mapState({
      account: state => state.account
    }),
    twofaState: function() {
      return this.account.status.twoFaSubmitFailure === true ? false : null;
    }
  },
  mounted() {
    this.$nextTick(() => this.$refs.twofa[0].focus());
  },
  methods: {
    ...mapActions("account", ["login", "logout", "twoFaSubmit", "getRoles"]),
    advance(n, e) {
      e.preventDefault();
      if (this.boxValues[n]) {
        this.boxValues[n] = (Math.abs(e.data) % 10).toString();
        const that = e.target.nextElementSibling;
        if (that !== null && that.className !== "invalid-feedback") {
          that.focus();
        } else {
          this.submission();
        }
      }
    },
    back(n, e) {
      if (!e.target.value) {
        this.boxValues[n] = null;
        const that = e.target.previousElementSibling;
        if (that !== null) {
          that.focus();
          that.value = "";
        }
      }
    },
    submission() {
      this.$refs.submit.click();
    },
    twoFaPaste(e) {
      e.preventDefault();
      const pasted = e.clipboardData.getData("Text").split("");
      for (let i = 1; i <= 6; i++) {
        this.boxValues[i] = pasted[i - 1];
      }
      if (this.boxValues[6]) {
        this.submission();
      }
    },
    concatenateCode() {
      const code =
        this.boxValues["1"] +
        "" +
        this.boxValues["2"] +
        "" +
        this.boxValues["3"] +
        "" +
        this.boxValues["4"] +
        "" +
        this.boxValues["5"] +
        "" +
        this.boxValues["6"];
      return code;
    }
  }
};
</script>

<style scoped>
input {
  width: 15.53%;
  text-align: center;
  margin-right: 5px;
}

.noMargin {
  margin-right: 0px;
}

.buttonContainer {
  margin-top: 10px;
}

.noDisplay {
  display: unset;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
</style>
