<template>
    <div>
        <h4 class="group-title">Checkpoint Settings</h4>
        <!--p class="bold description">Checkpoint Settings</p-->
        <p class="description">Configure your Checkpoint options.</p>
        <!--checkpoints-->
        <div class="groupSet card" v-for="flow in $store.state.settings.settings.Flows" :key="flow.ID">
            <h4 class="header-title">{{ flow.Name }}</h4>
            <p>{{ flow.Description }}</p>
            <table class="mb-0 table-centered" style="margin-left: 0; margin-right: 0;">
                <thead>
                    <tr class="TableHeader">
                        <td>Name</td>
                        <td>Active</td>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="service in flow.Services">
                        <tr v-for="option in service.options" :key="option.optionTypeID">
                            <td><strong>{{ option.name }}</strong> :<br/> {{ option.description }}</td>
                            <td class="OptionSwitch">
                                <div>
                                    <input type="checkbox" :id="flow.ID + '-' + service.id + '-' + option.optionTypeID" :ref="flow.ID + '-' + service.id + '-' + option.optionTypeID" :checked="option.id ? true : false" @change="TogglePass(flow.ID, service.id, option)" :value="true" data-switch="success" />
                                    <label :for="flow.ID + '-' + service.id + '-' + option.optionTypeID" data-on-label="On" data-off-label="Off" class="mb-0 d-block"></label>
                                </div>
                            </td>
                        </tr>
                    </template>
                </tbody>

            </table>
            <!-- <div style="display: none;">
                <div class="settingsLabel">
                    <label class="form-label">Option: </label>
                </div>
                <div class="settingsDiv" v-if="flags === 'loaded'">
                    <input type="checkbox" :id="flow.Name" :ref="flow.Name" :checked="$store.state.settings.settings.PassIds[flow.Name] ? true : false" @change="TogglePass(flow, flow.Name)" :value="true" data-switch="success" />
                    <label :for="flow.Name" data-on-label="On" data-off-label="Off" class="mb-0 d-block"></label>
                    <span class="settingsToolTip">When on, the Checkpoint will be waived.</span>
                </div>
                
            </div> -->
        </div>
    </div>
</template>

<script>
import appConfig from "@/app.config";
import { mapState, mapActions } from "vuex";
import { nextTick } from 'vue';

export default {
    name: "OptionPage",
    page: {
        title: "autopass",
        meta: [{ name: "description", content: appConfig.description }]
    },
    data() {
        return {
            flags: '',
            deletePass: {}
        }
    },
    methods:
    {
        ...mapActions("settings", [/*"getOptionFlows",*/ "getOptionSettings", "addOption", "deleteOption"]),
        TogglePass(flowID, ServID, option)
        {
            if(document.getElementById(flowID + "-" + ServID + "-" + option.optionTypeID).checked)
            {
                this.addOption({Id: null, OptionTypeID: option.optionTypeID , ServiceFlowStepId: option.stepIDs[0]});
            }
            else
            {
                this.deleteOption({Id: option.id, OptionTypeID: option.optionTypeID , ServiceFlowStepId: option.stepIDs[0]});
            }

        },
        PassStatus(i, stop) 
        {
            if (i == null) {
                this.PassStatus(100, 0);
            }
            const timeout = 240000;
            if (stop > timeout) {
                alert("Timed Out");
            } else if (this.$store.state.settings.Status.getOptionSettingsRequestLoading) {
                const temp = stop + i;
                setTimeout(() => {
                this.PassStatus(i, temp);
                }, i);
            } else {
                    this.flags = 'loaded';
            }
        },
    },
    created() {
        //this.getOptionFlows();
        this.getOptionSettings();
    },
    mounted() {
        this.PassStatus(1, 0)
    }
}
</script>

<style lang="scss" scoped>
.settingsLabel
{
    display:table-cell;
    width:fit-content;
    padding:5px;
    vertical-align: top;
}

.settingsDiv
{
    display:table-cell;
    width:fit-content;
    padding:5px;
    vertical-align: text-top;
}

.OptionSwitch
{
    vertical-align: top !important;
}

.TableHeader
{
    font-weight: bold;

}

td
{
    padding: 12px 0 12px 0;
}
table
{
    margin-left: 12px;
    margin-right: 12px;
    width: auto;
}

</style>