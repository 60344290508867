import { userService } from "../_services";

const state = {
  all: {},
  management: {}
};

const actions = {
  getAll({ commit }) {
    commit("getAllRequest");

    userService.getAll().then(
      users => commit("getAllSuccess", users),
      error => commit("getAllFailure", error)
    );
  },

  async getSubscriberUsers({ commit })
  {
    commit("getSubscriberUsersRequest");
    await userService.getSubscriberUsers().then(
        users => commit("getSubscriberUsersSuccess", users),
        error => commit("getSubscriberUsersFailure", error)
    );
  },

  async commitSubscriberUser({ commit }, user)
  {
    commit("commitSubscriberUserRequest");
    await userService.commitSubscriberUser(user).then(
        users => commit("commitSubscriberUserSuccess", users),
        error => commit("commitSubscriberUserFailure", error)
    );
  },

  async getRoles({ commit })
  {
    commit("getRolesRequest");
    await userService.getRoles().then(
        roles => commit("getRolesSuccess", roles),
        error => commit("getRolesFailure", error)
    );
  },

  async getTimezones({ commit })
  {
    commit("getTimezonesRequest");
    await userService.getTimezones().then(
        roles => commit("getTimezonesSuccess", roles),
        error => commit("getTimezonesFailure", error)
    );
  },

  delete({ commit }, id) {
    commit("deleteRequest", id);

    userService.delete(id).then(
      user => commit("deleteSuccess", id),
      error => commit("deleteFailure", { id, error: error.toString() })
    );
  }
};

const mutations = {
  getAllRequest(state) {
    state.all = { loading: true };
  },
  getAllSuccess(state, users) {
    state.all = { items: users };
  },
  getAllFailure(state, error) {
    state.all = { error };
  },
  deleteRequest(state, id) {
    // add 'deleting:true' property to user being deleted
    state.all.items = state.all.items.map(user =>
      user.id === id ? { ...user, deleting: true } : user
    );
  },
  deleteSuccess(state, id) {
    // remove deleted user from state
    state.all.items = state.all.items.filter(user => user.id !== id);
  },
  deleteFailure(state, { id, error }) {
    // remove 'deleting:true' property and add 'deleteError:[error]' property to user
    state.all.items = state.items.map(user => {
      if (user.id === id) {
        // make copy of user without 'deleting:true' property
        const { deleting, ...userCopy } = user;
        // return copy of user with 'deleteError:[error]' property
        return { ...userCopy, deleteError: error };
      }

      return user;
    });
  },

  getSubscriberUsersRequest(state) {
    state.management.usersLoading = true;
  },
  getSubscriberUsersSuccess(state, users) {
    state.management.usersLoading = false;
    state.management.users = users.filter(x => x.apiKey === null && x.serviceFlowID === null);
    state.management.APIUsers = users.filter(x => x.apiKey !== null && x.serviceFlowID !== null);
  },
  getSubscriberUsersFailure(state, error) {
    state.management.usersLoading = false;
    console.error(error);
  },

  commitSubscriberUserRequest(state) {
    state.management.commitLoading = true;
  },
  commitSubscriberUserSuccess(state, users) {
    state.management.commitLoading = false;
  },
  commitSubscriberUserFailure(state, error) {
    state.management.commitLoading = false;
    console.error(error);
  },

  getRolesRequest(state) {
    state.management.rolesLoading = true;
  },
  getRolesSuccess(state, roles) {
    state.management.rolesLoading = false;
    state.management.roles = roles;
  },
  getRolesFailure(state, error) {
    state.management.rolesLoading = false;
    console.error(error);
  },

  getTimezonesRequest(state) {
    state.management.timezonesLoading = true;
  },
  getTimezonesSuccess(state, zones) {
    state.management.timezonesLoading = false;
    state.management.timezones = zones;
  },
  getTimezonesFailure(state, error) {
    state.management.timezonesLoading = false;
    console.error(error);
  },
};

export const users = {
  namespaced: true,
  state,
  actions,
  mutations
};
